import { ref } from 'vue'

const treatmentDate = ref('')

const useAdditionalFields = () => {

  return {
    treatmentDate
  }
}

export default useAdditionalFields
