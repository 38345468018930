import axios from 'axios'
import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'
import { useI18n } from 'vue-i18n'
import useTimestamp from '@/composables/useTimestamp'
import { acssb_coords } from '@/helpers'
import useScore from '@/composables/useScore'
import useAdditionalFields from '@/composables/useAdditionalFields'

const useCreateAcssAPdf = () => {
  const { scores, calcScore } = useScore()
  const { locale } = useI18n()
  const { date_f, time_f } = useTimestamp()
  const { treatmentDate } = useAdditionalFields()

  const getPdfName = () => {
    const tr = {
      'en': 'results',
      'de': 'Ergebnisse',
      'ru': 'результаты',
      'fr': 'résultats',
      'it': 'risultati',
      'ko': '결과',
      'pl': 'wyniki',
      'es': 'resultados',
    }

    return tr[locale.value]
  }

  const getPdf = () => {
    window.fbq('track', 'ViewContent', { content_name: 'Donwload ACSS part B results pdf'})
    getPdfName()
    axios.get(
      `${process.env.VUE_APP_BASE_URL}/pdf/acssb_${locale.value}.pdf`,
      { responseType: 'arraybuffer'}
    ).then(res => {

        PDFDocument.load(res.data).then(p => {

          const pages = p.getPages()
          const firstPage = pages[0]

          firstPage.drawText(treatmentDate.value, {
            x: acssb_coords.firstPage.treatment_date[0],
            y: acssb_coords.firstPage.treatment_date[1],
            size: 10
          })

          firstPage.drawText(time_f.value, {
            x: acssb_coords.firstPage.date.hour[0],
            y: acssb_coords.firstPage.date.hour[1],
            size: 10
          })

          firstPage.drawText(date_f.value, {
            x: acssb_coords.firstPage.date.date[0],
            y: acssb_coords.firstPage.date.date[1],
            size: 10
          })
          
          for (let i in scores.value) {
            const quest = scores.value[i]

            if (quest && Object.keys(quest).length > 0) {
              
              for (let j in quest){
                const point = scores.value[i][j]

                if (typeof point === 'number' || typeof point === 'string') {
                  
                  const place = `point_${point}`

                  firstPage.drawText('X', {
                    x: acssb_coords.firstPage[j][place][0],
                    y: acssb_coords.firstPage[j][place][1],
                    size: 10
                  })
                }
                
              }

            }
            
          }

          const score_1 = calcScore(scores.value['2']).toString()
          const score_2 = calcScore(scores.value['3']).toString()
          const score_3 = calcScore(scores.value['4']).toString()

          firstPage.drawText(score_1, {
            x: acssb_coords.firstPage.scores.id_1[0],
            y: acssb_coords.firstPage.scores.id_1[1],
            size: 10
          })
          firstPage.drawText(score_2, {
            x: acssb_coords.firstPage.scores.id_2[0],
            y: acssb_coords.firstPage.scores.id_2[1],
            size: 10
          })
          firstPage.drawText(score_3, {
            x: acssb_coords.firstPage.scores.id_3[0],
            y: acssb_coords.firstPage.scores.id_3[1],
            size: 10
          })

          p.save()
            .then(r => 
              download(r, `${getPdfName()}.pdf`, 'application/pdf')
            )
        })
      })
      .catch(err => console.log(err))
  }

  return {
    getPdf
  }
}

export default useCreateAcssAPdf
